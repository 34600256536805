import { createRouter, createWebHistory } from 'vue-router'
import LogIn from '../views/LogIn.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: LogIn
  },
  {
    path: '/portal',
    name: 'portal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PortalView.vue')
  },
  {
    path:'/task',
    name:'task',
    component:()=>import('../views/TaskRunning.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router