<template>
     <div>
        <h1>This is wechat</h1>
        <a href="/portal" target="_blank" rel="noopener">Portal</a>
     </div>
</template>

<script>
export default {
   data() {

   },
   created() {
      localStorage.setItem("user",1);
      localStorage.setItem("group",1);
   }
}
</script>